import React, { useState } from "react";
import "./App.css";
import {
  Car,
  ChevronRight,
  Plane,
  ShieldCheck,
  CalendarCheck,
  Hammer,
} from "lucide-react";

import SendEmail from "./components/email/SendEmail";

import imageChevrolet from "./assets/images/chevrolet.png";
import imageSedan from "./assets/images/hyundai.jpg";
import imageFord from "./assets/images/ford.jpg";
import logo from "./assets/images/mrautoprestigeback.jpg";
import logo2 from "./assets/images/logo2.jpg";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";

function App() {
  const [language, setLanguage] = useState("es"); // Estado para controlar el idioma
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleLanguage = () => {
    setLanguage(language === "es" ? "en" : "es"); // Cambiar entre español e inglés
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const vehicles = [
    {
      id: 1,
      name: language === "es" ? "Chevorlet Tahoe" : "Chevorlet Tahoe",
      image: imageChevrolet,
    },
    {
      id: 2,
      name: language === "es" ? "Hyndai Santafe" : "Hyndai Santafe",
      image: imageSedan,
    },
    {
      id: 3,
      name: language === "es" ? "Ford Explorer" : "Ford Explorer",
      image: imageFord,
    },
  ];

  return (
    <div className="bg-black text-white min-h-screen">
      <WhatsAppButton />
      <header className="bg-black py-4">
        <nav className="container mx-auto flex justify-between items-center">
          <div className="text-2xl font-bold text-yellow-400 flex">
            <img
              src={logo2}
              alt="logoMrAutoPrestige"
              className="w-12 p-1 mr-2"
            />
            Mr Auto Prestige & Security
          </div>

          {/* Burger icon visible only on small screens */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          {/* Menu for larger screens */}
          <ul className="hidden md:flex space-x-4">
            <li>
              <a
                className="hover:text-gold transition-colors"
                href="#servicios"
              >
                {language === "es" ? "Servicios" : "Services"}
              </a>
            </li>
            <li>
              <a className="hover:text-gold transition-colors" href="#acerca">
                {language === "es" ? "Acerca de" : "About Us"}
              </a>
            </li>
            <li>
              <a className="hover:text-gold transition-colors" href="#contacto">
                {language === "es" ? "Contacto" : "Contact"}
              </a>
            </li>
          </ul>

          {/* Language toggle */}
          <button
            onClick={toggleLanguage}
            className="bg-yellow-900 text-black px-4 py-2 rounded-full font-bold hover:bg-yellow-800 transition-colors hidden md:block"
          >
            <img
              src={
                language === "es"
                  ? "https://cdn-icons-png.flaticon.com/512/197/197484.png"
                  : "https://cdn-icons-png.flaticon.com/512/197/197593.png"
              }
              alt={language === "es" ? "United States Flag" : "Spain Flag"}
              className="w-6 h-6"
            />
          </button>
        </nav>

        {/* Dropdown menu for mobile */}
        {menuOpen && (
          <ul className="md:hidden flex flex-col items-center space-y-4 mt-4">
            <li>
              <a
                className="text-white hover:text-gold transition-colors"
                href="#servicios"
              >
                {language === "es" ? "Servicios" : "Services"}
              </a>
            </li>
            <li>
              <a
                className="text-white hover:text-gold transition-colors"
                href="#acerca"
              >
                {language === "es" ? "Acerca de" : "About Us"}
              </a>
            </li>
            <li>
              <a
                className="text-white hover:text-gold transition-colors"
                href="#contacto"
              >
                {language === "es" ? "Contacto" : "Contact"}
              </a>
            </li>
            <button
              onClick={toggleLanguage}
              className="bg-yellow-500 text-black px-4 py-2 rounded-full font-bold hover:bg-yellow-800 transition-colors"
            >
              {language === "es" ? "English" : "Español"}
            </button>
          </ul>
        )}
      </header>

      <main>
        <section className="bg-gradient-to-r from-black to-gray-900 py-20">
          <div className="container mx-auto text-center">
            <p className="text-xl mb-8">
              {language === "es"
                ? "Somos una empresa colombiana de seguridad privada que opera desde el año 2023, la cual busca atender tanto a los sectores públicos como privados para optimizar la comercialización de su imagen y atenciones mercantiles, comprometiéndose a brindar una atención personalizada según las necesidades de cada cliente."
                : "We are a Colombian private security company that has been operating since 2023. We serve both public and private sectors to optimize the marketing of their image and commercial attentions, committing to provide personalized attention according to the needs of each client."}
            </p>
            <a
              href="#contacto"
              className="bg-yellow-500 text-black px-6 py-3 rounded-full font-bold hover:bg-yellow-800 transition-colors inline-flex items-center"
            >
              {language === "es" ? "Solicitar cotización" : "Request a Quote"}
              <ChevronRight className="ml-2" />
            </a>
          </div>
        </section>

        {/* Servicios Section */}
        <section
          id="servicios"
          className="py-16"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-center text-gold">
              {language === "es" ? "Nuestros Servicios" : "Our Services"}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-black p-6 rounded-lg shadow-lg">
                <Car className="text-gold w-12 h-12 mb-4" />
                <h3 className="text-xl font-bold mb-2">
                  {language === "es"
                    ? "Autos Blindados y Convencionales"
                    : "Armored and Conventional Cars"}
                </h3>
                <p>
                  {language === "es"
                    ? "Proveemos vehículos blindados y convencionales para garantizar la seguridad y comodidad de nuestros clientes."
                    : "We provide armored and conventional vehicles to ensure the safety and comfort of our clients."}
                </p>
              </div>
              <div className="bg-black p-6 rounded-lg shadow-lg">
                <Hammer className="text-gold w-12 h-12 mb-4" />
                <h3 className="text-xl font-bold mb-2">
                  {language === "es"
                    ? "Repuestos para Automóviles"
                    : "Car Parts"}
                </h3>
                <p>
                  {language === "es"
                    ? "Ofrecemos repuestos de alta calidad para mantener tus vehículos en óptimas condiciones."
                    : "We offer high-quality parts to keep your vehicles in optimal condition."}
                </p>
              </div>
              <div className="bg-black p-6 rounded-lg shadow-lg">
                <Plane className="text-gold w-12 h-12 mb-4" />
                <h3 className="text-xl font-bold mb-2">
                  {language === "es" ? "Drones" : "Drones"}
                </h3>
                <p>
                  {language === "es"
                    ? "Utilizamos drones para diversas aplicaciones, incluyendo vigilancia y logística."
                    : "We use drones for various applications, including surveillance and logistics."}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-4">
              <div className="bg-black p-6 rounded-lg shadow-lg">
                <ShieldCheck className="text-gold w-12 h-12 mb-4" />
                <h3 className="text-xl font-bold mb-2">
                  {language === "es"
                    ? "Seguridad Electrónica"
                    : "Electronic Security"}
                </h3>
                <p>
                  {language === "es"
                    ? "Implementamos sistemas de seguridad electrónica avanzados para proteger tus instalaciones y bienes."
                    : "We implement advanced electronic security systems to protect your facilities and assets."}
                </p>
              </div>
              <div className="bg-black p-6 rounded-lg shadow-lg">
                <CalendarCheck className="text-gold w-12 h-12 mb-4" />
                <h3 className="text-xl font-bold mb-2">
                  {language === "es"
                    ? "Logística de Seguridad en Eventos"
                    : "Event Security Logistics"}
                </h3>
                <p>
                  {language === "es"
                    ? "Planificamos y ejecutamos estrategias de seguridad para eventos, asegurando su éxito y tranquilidad."
                    : "We plan and execute security strategies for events, ensuring their success and peace of mind."}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="galeria" className="py-16 bg-gray-900">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-center text-yellow-400">
              {language === "es"
                ? "Nuestros Autos Blindados"
                : "Our Armored Cars"}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {vehicles.map((vehicle) => (
                <div
                  key={vehicle.id}
                  className="bg-gray-900 rounded-lg overflow-hidden shadow-lg"
                >
                  <img
                    src={vehicle.image}
                    alt={vehicle.name}
                    width={400}
                    height={300}
                    className="w-full h-48 object-contain"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold text-gold">
                      {vehicle.name}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="acerca" className="py-16">
          <div className="container mx-auto">
            <h1 className="text-3xl font-bold mb-8 text-center text-yellow-400">
              {language === "es" ? "Acerca de Nosotros" : "About Us"}
            </h1>
            <div className="max-w-3xl mx-auto text-center">
              <h3 className="text-3xl font-bold mb-8 text-center text-yellow-400">
                {language === "es" ? "Misión" : "Mission"}
              </h3>
              <p className="mb-4">
                {language === "es"
                  ? "Nos comprometemos a brindar una atención personalizada, adaptándonos a las necesidades específicas de cada cliente. Integramos valores corporativos en todas nuestras operaciones para optimizar la comercialización de nuestra imagen y servicios."
                  : "We are committed to providing personalized attention, adapting to the specific needs of each client. We integrate corporate values into all our operations to optimize the commercialization of our image and services."}
              </p>
              <hr className="mb-4" />
              <h3 className="text-3xl font-bold mb-8 text-center text-yellow-400">
                {language === "es" ? "Visión" : "Vision"}
              </h3>
              <p className="mb-4">
                {language === "es"
                  ? "Nos comprometemos a brindar una atención personalizada, adaptándonos a las necesidades específicas de cada cliente. Integramos valores corporativos en todas nuestras operaciones para optimizar la comercialización de nuestra imagen y servicios."
                  : "We are committed to providing personalized attention, adapting to the specific needs of each client. We integrate corporate values into all our operations to optimize the commercialization of our image and services."}
              </p>
              <hr className="mb-4" />
              <h3 className="text-3xl font-bold mb-8 text-center text-yellow-400">
                {language === "es" ? "Objetivo" : "Objective"}
              </h3>
              <p className="mb-4">
                {language === "es"
                  ? "Ofrecer servicios de alta calidad centrados en los estándares de desplazamiento y utilidad para sus clientes, al tiempo que garantiza la durabilidad de los materiales adquiridos para aumentar la confianza del consumidor en la empresa."
                  : "To offer high-quality services focused on mobility and utility standards for its clients, while ensuring the durability of the materials acquired to increase consumer confidence in the company."}
              </p>
            </div>
          </div>
        </section>

        <section
          id="contacto"
          className="py-16 bg-gray-900"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-center text-gold">
              {language === "es" ? "Contáctenos" : "Contact Us"}
            </h2>
            <SendEmail language={language} />
          </div>
        </section>
      </main>

      <footer className="bg-black py-8">
        <div className="container mx-auto text-center">
          <p>
            &copy; 2024 Mr Auto Prestige.{" "}
            {language === "es"
              ? "Todos los derechos reservados."
              : "All rights reserved."}
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
