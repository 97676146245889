import React, { useState } from "react";

const SendEmail = ({ language }) => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("message", message);

    try {
      const response = await fetch(
        "https://mrautoprestigesecurity.services/email/sendEmail.php",
        {
          method: "POST",
          body: formData,
        },
      );
      const result = await response.text();
      setResponseMessage(result);
    } catch (error) {
      setResponseMessage(
        language === "es"
          ? "Error al enviar el correo"
          : "Error sending the email",
      );
    } finally {
      setLoading(false); // Quitar el estado de "cargando" o "loading" después del envío
    }
  };

  return (
    <div className="container mx-auto">
      <div className="max-w-md mx-auto bg-black p-8 rounded-lg shadow-lg">
        <form onSubmit={sendEmail}>
          <div className="mb-4">
            <label htmlFor="asunto" className="block mb-2">
              {language === "es" ? "Asunto" : "Subject"}
            </label>
            <input
              type="text"
              id="asunto"
              className="w-full px-3 py-2 bg-gray-800 rounded"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-3 py-2 bg-gray-800 rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mensaje" className="block mb-2">
              {language === "es" ? "Mensaje" : "Message"}
            </label>
            <textarea
              id="mensaje"
              rows={4}
              className="w-full px-3 py-2 bg-gray-800 rounded"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-yellow-500 text-black py-2 rounded font-bold hover:bg-yellow-800 transition-colors"
            disabled={loading} // Desactivar el botón mientras está cargando
          >
            {loading
              ? language === "es"
                ? "Cargando..."
                : "Loading..."
              : language === "es"
              ? "Enviar mensaje"
              : "Send Message"}
          </button>
          <p className="mt-4 text-white">{responseMessage}</p>
        </form>
      </div>
    </div>
  );
};

export default SendEmail;
