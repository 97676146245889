import React from "react";
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  const phoneNumber = "+573204655476";
  const message = "¡Hola! Quiero más información sobre sus servicios.";

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message,
  )}`;

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
      <div className="whatsapp-float">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          className="whatsapp-logo"
        />
      </div>
    </a>
  );
};

export default WhatsAppButton;
